// httpClient.js
import { API_CONFIG } from './apiConfig';

const BASE_URL = 'https://portal.icentral.com.mx/crm/api/v1.0/';
const ONU_API_BASE_URL = 'https://icentral.smartolt.com/api/';

const httpClient = async (endpoint, options = {}, apiBaseURL = BASE_URL) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-Auth-App-Key': API_CONFIG.appKey
  });

  // Añadir encabezado de contenido si hay datos en el cuerpo
  if (options.body && !(options.body instanceof FormData)) {
    options.body = JSON.stringify(options.body);
  }

  const config = {
    ...options,
    headers
  };

  const response = await fetch(`${apiBaseURL}${endpoint}`, config);
  if (!response.ok) {
    const errorBody = await response.text();
    console.error("Error response body:", errorBody);
    throw new Error(`Error en la solicitud a la API: ${errorBody}`);
  }

  const contentType = response.headers.get("Content-Type");
  if (contentType && contentType.includes("application/json")) {
    return response.json();
  } else {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.startsWith('image/')) {
      return response.blob();
    }
    return response;
  }
};

export const getOnuSignal = async (serial) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-Token': 'f824840284e84f6ab00bbed595e53574',
    'Access-Control-Allow-Origin': '*'
  });

  try {
    const response = await httpClient(`onu/get_onu_signal/${serial}`, { headers }, ONU_API_BASE_URL);
    
    if (!response.ok) {
      const errorText = await response.text(); // o response.json() si la respuesta es JSON
      console.error(`Error en la solicitud a la API. Código: ${response.status}, Mensaje: ${errorText}`);
      throw new Error(`Error en la solicitud a la API. Código: ${response.status}, Mensaje: ${errorText}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error al obtener la señal de ONU:", error);
    throw error;
  }
};

export default httpClient;



 
