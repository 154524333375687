// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut, setPersistence, browserLocalPersistence } from 'firebase/auth';

const firebaseConfig = {
  // Tu configuración de Firebase aquí
  apiKey: "AIzaSyAfhv8uO6mtHL71Piu8-DQ0-52BeCNpg8M",
  authDomain: "icentraltech-6c981.firebaseapp.com",
  projectId: "icentraltech-6c981",
  storageBucket: "icentraltech-6c981.appspot.com",
  messagingSenderId: "430015942616",
  appId: "1:430015942616:web:592eaba22767c9752e6cad"
};

const firebaseApp = initializeApp(firebaseConfig);

// Inicializa Firebase Authentication
const auth = getAuth(firebaseApp);

// Exporta la app y la autenticación para su uso en otras partes de tu aplicación
export { firebaseApp, auth };