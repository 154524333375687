import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Paper, Typography, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, currentUser } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    // Redirige automáticamente si el usuario ya está autenticado
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await login(email, password);
      navigate('/dashboard'); // Redirige al usuario al Dashboard después del inicio de sesión
    } catch (error) {
      setError('Falló el inicio de sesión'); // Maneja errores de inicio de sesión
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <Grid item xs={10} sm={8} md={6} lg={4}>
      <Paper elevation={3} style={{ padding: '20px', maxWidth: 300, textAlign: 'center' }}>
        <LockOutlinedIcon style={{ fontSize: '40px', marginBottom: '20px' }} />
        <Typography variant="h5" gutterBottom>
          Iniciar Sesión
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Correo Electrónico"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Contraseña"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: '20px' }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Iniciar Sesión'}
          </Button>
        </form>
        {error && <Typography color="error">{error}</Typography>}
      </Paper>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
