import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import JobDetails from './components/JobDetails';
import {ThemeContextProvider} from './components/ThemeContext';
import InicioServicio from './components/InicioServicio';
import NuevoTrabajoForm from './components/NuevoTrabajoForm';

function App() {

  return (
    
    <AuthProvider>
      
     
      <Router>
      <ThemeContextProvider>
        <Routes>
        <Route
  path="/"
  element={
    <Navigate
      to={localStorage.getItem('token') ? '/dashboard' : '/login'}
    />
  }
/>
          <Route path="/login" element={<Login />} />
          

          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }/>
          <Route path="/job/:jobId/:userId" element={
            <ProtectedRoute>
              <JobDetails />
            </ProtectedRoute>
          }/>
             <Route path="/inicioservicio" element={
            <ProtectedRoute>
              <InicioServicio />
            </ProtectedRoute>
          }/>
           <Route path="/nuevotrabajo" element={
            <ProtectedRoute>
              <NuevoTrabajoForm />
            </ProtectedRoute>
          }/>
           
        </Routes>
       
        </ThemeContextProvider>
      </Router>

     
    </AuthProvider>
    
  );
}

export default App;