// ThemeContext.js
import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { getTheme } from './theme';

const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = getTheme(darkMode);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
    <ThemeProvider theme={getTheme(darkMode)}>
      {children}
    </ThemeProvider>
  </ThemeContext.Provider>
  );
};
