// src/components/InicioServicio.js
import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, TextField, Container } from '@mui/material';
import Layout from './Layout';
const InicioServicio = ({ onNext }) => {
  const [unidad, setUnidad] = useState('');
  const [cajaServicio, setCajaServicio] = useState('');
  const [candadoId, setCandadoId] = useState('');
  const [puerto, setPuerto] = useState('');

  const obtenerPuertosDesdeAPI = async () => {
    // Lógica para obtener la lista de puertos desde la API
    // Puedes utilizar fetch o axios para hacer la solicitud
    // Ejemplo: const response = await fetch('url_de_tu_api');
    //         const data = await response.json();
    //         setPuertos(data.puertos);
  };

  useEffect(() => {
    obtenerPuertosDesdeAPI();
  }, []);

  const handleGuardar = () => {
    // Lógica para guardar los datos en Firestore
    // Utiliza la instancia de Firebase y firestore para almacenar los datos
  };

  return (
    <Layout>
      <Container>
    <div>
            <h1>Inicio Servicio</h1>

      <FormControl>
        <InputLabel>Selecciona unidad</InputLabel>
        <Select label="Selecciona unidad" value={unidad} onChange={(e) => setUnidad(e.target.value)}>
          {/* Opciones para el droplist */}
          <MenuItem value="opcion1">eco 01</MenuItem>
          <MenuItem value="opcion2">eco 02</MenuItem>
        </Select>
<br/>
        
        <TextField
         label="Caja de Servicio"
         variant="outlined"
         value={cajaServicio}
         onChange={(e) => setCajaServicio(e.target.value)}
         />
         <br></br>
         <TextField
         label="Candado"
         variant="outlined"
         value={candadoId}
         onChange={(e) => setCandadoId(e.target.value)}
         />      
<br/>

         <TextField
         label="Posicion del puerto"
         variant="outlined"
         />


      </FormControl>
      <br />
      {/* Otros campos y botón para guardar */}
      <Button onClick={handleGuardar} variant="contained" color="primary">
        Guardar
      </Button>
      
    </div>
    </Container>
    </Layout>
  );
};

export default InicioServicio;
