import React, { createContext, useContext, useState, useEffect } from 'react';
import {  auth } from '../firebase/firebaseConfig';
import { signInWithEmailAndPassword, onAuthStateChanged, signOut, setPersistence, browserLocalPersistence } from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        // Limpiar la suscripción al desmontar
        return unsubscribe;
    }, []);

    const login = async (email, password) => {
        try {
            await setPersistence(auth, browserLocalPersistence); // Configura la persistencia
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            // ... Manejo adicional después del inicio de sesión exitoso
        } catch (error) {
            // ... Manejo de errores
            throw error; // Lanza el error para manejarlo en el componente de login
        }
    };

    const logout = () => {
        return signOut(auth);
    };

    const value = {
        currentUser,
        login,
        logout,
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
