import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { fetchAllUsers, fetchUserTickets, fetchUserJobs  } from './apiService';
import {
   Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    MenuItem,
    Box,
    Select,
    Container, Grid,TablePagination
} from '@mui/material';

import TaskIcon from '@mui/icons-material/Task';
import { useTheme } from '@mui/material/styles';
import Layout from './Layout'; 

const Dashboard = () => {
    const { currentUser, logout } = useAuth();
    const [userJobs, setUserJobs] = useState([]);
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [userTickets, setUserTickets] = useState([]);
    const [filterStatus, setFilterStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const paginatedJobs = userJobs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    
    useEffect(() => {

    
      const obtenerDatosUsuario = async () => {
        try {
            const users = await fetchAllUsers();
            setAllUsers(users);

            const loggedInUser = users.find(user => user.email === currentUser.email);
            setUserDetails(loggedInUser);

            if (loggedInUser && loggedInUser.id) {
                const tickets = await fetchUserTickets(loggedInUser.id);
                const jobs = await fetchUserJobs(loggedInUser.id, filterStatus);
                setUserTickets(tickets);
                setUserJobs(jobs);
                setPage(0);

              
              
            }
        } catch (error) {
            console.error('Error al obtener datos:', error);
        }
    };
    if (currentUser && currentUser.email) {
      obtenerDatosUsuario();
  } else {
      console.log('Usuario actual no definido o sin email');
  }
      
        const obtenerTrabajosUsuario = async () => {
          setIsLoading(true); 
            try {
                if (currentUser && currentUser.id) {
                    const jobs = await fetchUserJobs(currentUser.id);
                    setUserJobs(jobs);
                }
            } catch (error) {
                console.error('Error al obtener trabajos:', error);
            }finally {
              setIsLoading(false); // Finaliza la carga
          }

        };

        obtenerTrabajosUsuario();
    }, [currentUser,filterStatus]);

    const handleFilterChange = (event) => {
      setFilterStatus(event.target.value);
      setPage(0);
  };
    

   

  const handleSelectJob = (jobId, userId) => {
    navigate(`/job/${jobId}/${userId}`);
};
    const formatearFecha = (fecha) => {
      const fechaObj = new Date(fecha);
      const hoy = new Date();
      
      if (fechaObj.toDateString() === hoy.toDateString()) {
        return `Hoy a las ${fechaObj.toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit', hour12: true })}`;
      } else {
        return fechaObj.toLocaleDateString('es-MX', { day: '2-digit', month: '2-digit', year: 'numeric' }) + 
               ' a las ' + 
               fechaObj.toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit', hour12: true });
      }
    };
    

 
  return (
    <Layout  >
        <Container >
            <Box mt={3} style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
                <Typography variant="h5" gutterBottom>
                    Trabajos Asignados: <Select value={filterStatus} onChange={handleFilterChange}>
                        <MenuItem value="0">Abierto</MenuItem>
                        <MenuItem value="1">En progreso</MenuItem>
                        <MenuItem value="2">Cerrado</MenuItem>
                    </Select>
                </Typography>
                {isLoading ? (
                    <Typography>Cargando trabajos...</Typography>
                ) : userJobs.length > 0 ? (
                  <>
                  <List>
                  {paginatedJobs.map((job, index) => (
                    <ListItem 
                      key={job.id} 
                      button 
                      onClick={() => handleSelectJob(job.id, userDetails.id)}
                      style={{ 
                        backgroundColor: index % 2 === 0 
                          ? theme.palette.mode === 'dark' ? theme.palette.background.level2 : '#f7f7f7'
                          : theme.palette.background.default, // Usa los colores del tema
                      }}
                    >
                      <ListItemIcon><TaskIcon /></ListItemIcon>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <ListItemText primary={job.title} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <ListItemText primary={formatearFecha(job.date)} />
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
                <TablePagination
                component="div"
                count={userJobs.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
                ) : (
                    <Typography>No hay trabajos asignados.</Typography>
                )}

                
            </Box>
        </Container>
    </Layout>
);
};

export default Dashboard;