import React, { useState, useEffect } from 'react';
import {
  AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem,
  ListItemText, Menu, MenuItem, Avatar as MuiAvatar, Box, Switch
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import ReactAvatar from 'react-avatar';
import { useAuth } from './AuthContext'; 
import { fetchAllUsers } from './apiService';
import { useThemeContext } from './ThemeContext';
import { useTheme } from '@mui/material/styles';
const Layout = ({   children  }) => {
    const { currentUser, logout } = useAuth();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { darkMode, toggleTheme } = useThemeContext();
    const theme = useTheme();
    
    
    const [userDetails, setUserDetails] = useState(null);
    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        logout();
        navigate('/login');
    };
    useEffect(() => {
        const obtenerDatosUsuario = async () => {
            try {
                const users = await fetchAllUsers();
                const loggedInUser = users.find(user => user.email === currentUser?.email);
                setUserDetails(loggedInUser);
            } catch (error) {
                console.error('Error al obtener datos del usuario:', error);
            }
        };

        if (currentUser?.email) {
            obtenerDatosUsuario();
        }
    }, [currentUser]);
    
    return (
        
      <Box sx={{ height: '100vh', width: '100vw', overflow: 'auto' }} style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Bienvenido, {userDetails ? userDetails.firstName : 'Cargando...'}
            </Typography>

            {/* Interruptor del Modo Nocturno */}
            <Switch
              checked={darkMode}
              onChange={toggleTheme}
              name="darkModeToggle"
            />

            {/* Botón del Avatar del Usuario */}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {currentUser && currentUser.photoURL 
                ? <MuiAvatar src={currentUser.photoURL} style={{ width: 30, height: 30 }} /> 
                : <ReactAvatar name={userDetails ? userDetails.firstName : 'U'} round={true} size="30" style={{ borderRadius: '50%' }} />}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
            >
                <List>
                    {/* Agrega aquí los elementos de tu menú lateral */}
                    <ListItem button onClick={() => navigate('/dashboard')}>
                        <ListItemText primary="Tareas" />
                    </ListItem>
                    <ListItem button onClick={() => navigate('/inicioservicio')}>
                        <ListItemText primary="Instalaciones" />
                    </ListItem>
                    <ListItem button onClick={() => navigate ('/nuevotrabajo')}>
                    <ListItemText primary="Nuevo Trabajo" />
                    </ListItem>
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
                {children}
            </Box>
        </Box>
        
    
    );
};

export default Layout;
