import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTasks, updateJobStatus,subirAdjunto, addComment, addAttachment, getJobComments, getJobAttachments, obtenerUrlAdjunto,getClienteDetalles,getClienteService,fetchAllUsers  } from './apiService';
import { Dialog,Paper, DialogTitle, DialogContent,Container, Typography, Box, Card, CardContent,Button, TextField, Input, Divider,ImageList, ImageListItem, ImageListItemBar, IconButton } from '@mui/material';
import Layout from './Layout';
import DirectionsIcon from '@mui/icons-material/Directions';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useAuth } from './AuthContext';
import InfoIcon from '@mui/icons-material/Info';
import GetAppIcon from '@mui/icons-material/GetApp';
import { getOnuSignal } from './httpClient';
import { format } from 'date-fns';
import axios from 'axios';

export const formatearTamañoArchivo = (tamañoEnBytes) => {
    const tamaños = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (tamañoEnBytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(tamañoEnBytes) / Math.log(1024)), 10);
    return Math.round(tamañoEnBytes / Math.pow(1024, i), 2) + ' ' + tamaños[i];
};
const JobDetails = () => {
    const { jobId, userId } = useParams();
    const [jobDetails, setJobDetails] = useState(null);
    const [comentarios, setComentarios] = useState([]);
    const [adjuntos, setAdjuntos] = useState([]);
    const [nuevoComentario, setNuevoComentario] = useState('');
    const [nuevoAdjunto, setNuevoAdjunto] = useState(null);
    const { currentUser } = useAuth();
   
    const [clienteDetalles, setClienteDetalles] = useState(null);
    const [servicioDetalles, setServicioDetalles] = useState(null);
    const [modalAbierto, setModalAbierto] = useState(false);
const [urlImagenActual, setUrlImagenActual] = useState('');

const abrirModalImagen = (url) => {
    setUrlImagenActual(url);
    setModalAbierto(true);
};

const cerrarModalImagen = () => {
    setModalAbierto(false);
};

    const obtenerUbicacionActual = () => {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject('La Geolocalización no está soportada por tu navegador.');
            } else {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            }
        });
    };
    
    const crearEnlaceGoogleMaps = async (gpsLat, gpsLon) => {
        try {
            const posicionActual = await obtenerUbicacionActual();
            const origenLat = posicionActual.coords.latitude;
            const origenLon = posicionActual.coords.longitude;
            const destino = `${gpsLat},${gpsLon}`;
    
            const enlace = `https://www.google.com/maps/dir/?api=1&origin=${origenLat},${origenLon}&destination=${destino}&travelmode=driving`;
            return enlace;
        } catch (error) {
            console.error('Error obteniendo la ubicación actual:', error);
            return null;
        }
    };
    
    const atributosAMostrar = ["NAP", "ONT Model","Candado", "GPON Port","GPON Serial" /* ... otros atributos deseados ... */];

    // Función para filtrar los atributos que se mostrarán
    const filtrarAtributos = (atributos) => {
        return atributos.filter((atributo) => atributosAMostrar.includes(atributo.name));
    };

    useEffect(() => {
        const cargarDatos = async () => {
            try {
                const detalles = await getTasks(jobId);
                setJobDetails(detalles);
                
                // Obtener detalles del cliente
                const clienteDetalles = await getClienteDetalles(detalles.clientId);
                    setClienteDetalles(clienteDetalles); // Almacenar en el estado

                // Aquí puedes almacenar los detalles del cliente en el estado
                if (clienteDetalles && clienteDetalles.contacts && clienteDetalles.contacts.length > 0) {
                    const telefonoCliente = clienteDetalles.contacts[0].phone;
                    if (telefonoCliente) {
                        
                        // Aquí puedes hacer algo más con el número de teléfono, como mostrarlo en la interfaz de usuario.
                    }
                }
                const servicioDetallesResponse = await getClienteService(detalles.clientId);
                

                if (Array.isArray(servicioDetallesResponse) && servicioDetallesResponse.length > 0) {
                    const primerServicio = servicioDetallesResponse[0];
                    setServicioDetalles(primerServicio);
            
                    // Obtener y mostrar datos de la nueva API usando el GPON Serial del servicio
                    if (primerServicio && primerServicio.attributes) {
                      const gponSerialAttribute = primerServicio.attributes.find(attr => attr.name === 'GPON Serial');
                      if (gponSerialAttribute) {
                        const gponSerial = gponSerialAttribute.value;
                        
                        try {
                          const onuSignalData = await getOnuSignal(gponSerial);
                          console.log('Datos de la señal de ONU:', onuSignalData);
                        } catch (onuSignalError) {
                          console.error('Error al obtener datos de la señal de ONU:', onuSignalError);
                        }
                      }
                    }
                  } else {
                    console.error("La respuesta de detalles del servicio no es válida:", servicioDetallesResponse);
                  }





                const comentariosCargados = await getJobComments(jobId);
                setComentarios(comentariosCargados);

                const comentariosConUsuarios = await Promise.all(comentariosCargados.map(async (comentario) => {
                    try {
                        const usuarios = await fetchAllUsers();
                        const usuario = usuarios.find(user => user.id === comentario.userId);
                        
                        return { ...comentario, usuarioNombre: usuario ? usuario.username : 'Usuario Desconocido' }; // Agrega el nombre del usuario al comentario
                    } catch (error) {
                        console.error("Error al obtener detalles del usuario:", error);
                        return comentario; // Retorna el comentario sin detalles del usuario si hay un error
                    }
                }));
        
                setComentarios(comentariosConUsuarios);
    
                const adjuntosCargados = await getJobAttachments(jobId);
                const adjuntosConUrls = await Promise.all(adjuntosCargados.map(async (adjunto) => {
                    try {
                        const blob = await obtenerUrlAdjunto(adjunto.id);
                        const url = URL.createObjectURL(blob);
                        return { ...adjunto, url };
                    } catch (error) {
                        console.error("Error al obtener URL para adjunto:", error);
                        return adjunto; // Retorna el adjunto sin url si hay un error
                    }
                }));
    
                setAdjuntos(adjuntosConUrls);
            } catch (error) {
                console.error("Error al cargar datos del trabajo:", error);
            }
        };
    
        cargarDatos();
    }, [jobId]);

    const iniciarServicio = () => {
        updateJobStatus(jobId, 1).then(() => {
            setJobDetails(prevDetails => ({ ...prevDetails, status: 1 }));
        }).catch(error => {
            console.error("Error al iniciar el servicio:", error);
        });
    };
 
    const cerrarServicio = () => {
        // Enviar comentarios y adjuntos
        addComment(jobId, /* userId */ nuevoComentario).catch(error => {
            console.error("Error al agregar comentario:", error);
        });
        if (nuevoAdjunto) {
            addAttachment(jobId, nuevoAdjunto.name, /* Convertir a base64 */).catch(error => {
            console.error("Error al agregar adjunto:", error);
            });
        }
        // Cambiar estado a cerrado
        updateJobStatus(jobId, 2).then(() => {
            setJobDetails(prevDetails => ({ ...prevDetails, status: 2 }));
        }).catch(error => {
            console.error("Error al cerrar el servicio:", error);
        });
    };

    const handleIrAGoogleMaps = async () => {
        if (jobDetails && jobDetails.gpsLat && jobDetails.gpsLon) {
            const enlaceMaps = await crearEnlaceGoogleMaps(jobDetails.gpsLat, jobDetails.gpsLon);
            if (enlaceMaps) window.open(enlaceMaps, "_blank");
        }
    };

    const guardarComentariosYAdjuntos = async () => {
        try {
            if (nuevoComentario) {
                console.log("currentUser:", currentUser);
                await addComment({
                    jobId: jobDetails.id,
                    message: nuevoComentario,
                    userId: parseInt(userId, 10)
                });
                setNuevoComentario('');
            }
            if (nuevoAdjunto) {
                await subirAdjunto(jobDetails.id, nuevoAdjunto, nuevoAdjunto.name);
                setNuevoAdjunto(null);
            }
            // Recargar comentarios y adjuntos
            const nuevosComentarios = await getJobComments(jobId);
            const nuevosAdjuntos = await getJobAttachments(jobId);
            setComentarios(nuevosComentarios);
            setAdjuntos(nuevosAdjuntos);
        } catch (error) {
            console.error("Error al guardar comentarios y adjuntos:", error);
        }
    };

    
    const enviarWhatsapp = () => {
        if (clienteDetalles && clienteDetalles.contacts && clienteDetalles.contacts.length > 0) {
          const telefonoCliente = clienteDetalles.contacts[0].phone;
      
          // Obtener detalles del trabajo
          const { firstName, lastName, fullAddress } = clienteDetalles;
          const { id } = jobDetails;
      
          // Construir el mensaje personalizado
          const mensaje = `Hola, ${firstName} ${lastName}, me comunico de *iCentral* para atender la orden ${id} con *Domicilio:* ${fullAddress}. *¿Me puede recibir?*`;
      
          // Codificar el mensaje para agregarlo a la URL de WhatsApp
          const mensajeCodificado = encodeURIComponent(mensaje);
      
          // Construir la URL de WhatsApp
          const urlWhatsapp = `https://wa.me/${telefonoCliente}?text=${mensajeCodificado}`;
      
          // Abrir la ventana de WhatsApp con el mensaje predefinido
          window.open(urlWhatsapp, '_blank');
        }
      };
    
      const llamarDirecto = () => {
        if (clienteDetalles && clienteDetalles.contacts && clienteDetalles.contacts.length > 0) {
          const telefonoCliente = clienteDetalles.contacts[0].phone;
          const urlLlamadaDirecta = `tel:${telefonoCliente}`;
          window.open(urlLlamadaDirecta, '_blank');
        }
      };

      const interpretarEstadoServicio = (estadoNum) => {
        const estadoMapping = {
          1: 'Activo',
          2: 'Cancelado',
          3: 'Suspendido',
          7: 'Instalación',
          8: 'Inactivo'
          // Puedes agregar más estados según sea necesario
        };
      
        // Devuelve la descripción correspondiente o 'Desconocido' si no hay coincidencia
        return estadoMapping[estadoNum] || 'Desconocido';
      };

    return (
        <Layout>
            <Container>
                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Detalles del Trabajo
                    </Typography>
                    {jobDetails ? (
                        <Card>
                            <CardContent>
                                <Typography variant="h5">{jobDetails.title}</Typography>
                                <Typography color="textSecondary">ID: {jobDetails.id}</Typography>
                                <Typography variant="body2">Fecha: {new Date(jobDetails.date).toLocaleString()}</Typography>
                                <Typography variant="body2">Duración: {jobDetails.duration} minutos</Typography>
                                <Typography variant="body2">Estado: {jobDetails.status === 0 ? 'Pendiente' : 'Completado'}</Typography>
                                <Typography variant="body2">Dirección: {jobDetails.address}</Typography>
                                {servicioDetalles && (
                    <div>
                        <Typography variant="h6">Detalles del Servicio:</Typography>
                        {/* Muestra aquí los detalles del servicio según tu estructura */}
                        <p>Nombre del Servicio: {servicioDetalles.name}</p>
                        <p>Tipo de Servicio: {interpretarEstadoServicio(servicioDetalles.status)}</p>
                        <Typography variant="subtitle1">Atributos del Servicio:</Typography>
                        <ul>
                        {filtrarAtributos(servicioDetalles.attributes).map((atributo) => (
                                <li key={atributo.id}>
                                    {atributo.name}: {atributo.value}
                                </li>
                            ))}
                        </ul>

                        {/* ... Otros atributos que desees mostrar */}
                    </div>
                )}
                                <Button
                                variant="contained"
                                color="primary"
                                startIcon={<DirectionsIcon />}
                                onClick={handleIrAGoogleMaps}
                                style={{ marginTop: 15 }}
                            >
                                Ir a Google Maps
                            </Button>
                            <Button
              variant="contained"
              color="success"  // Puedes ajustar el color según el tema de tu aplicación
              startIcon={<WhatsAppIcon />}
              onClick={enviarWhatsapp}
              style={{ marginTop: 15, marginLeft: 10 }}
            >
              Enviar a WhatsApp
            </Button>
            <Button
              variant="contained"
              color="info"  // Puedes ajustar el color según el tema de tu aplicación
              startIcon={<PhoneIcon />}
              onClick={llamarDirecto}
              style={{ marginTop: 15, marginLeft: 10 }}
            >
              Llamar Directo
            </Button>

                            </CardContent>
                        </Card>
                    ) : <Typography>Cargando...</Typography>}
                      


                </Box>
                {jobDetails && jobDetails.status === 0 && <Button onClick={iniciarServicio}>Iniciar Servicio</Button>}
                {jobDetails && jobDetails.status === 1 && (
                    <>
                        <Divider style={{ margin: '20px 0' }} />
                        <TextField label="Nuevo Comentario" value={nuevoComentario} onChange={(e) => setNuevoComentario(e.target.value)} fullWidth />
                        <Input type="file" onChange={(e) => setNuevoAdjunto(e.target.files[0])} fullWidth style={{ marginTop: 15 }} />
                        <Button onClick={guardarComentariosYAdjuntos} variant="contained" color="primary" style={{ marginTop: 15 }}>Guardar Comentarios y Adjuntos</Button>
                        <Button onClick={cerrarServicio} variant="contained" color="secondary" style={{ marginTop: 15, marginLeft: 15 }}>Finalizar Servicio</Button>
                    </>
                )}
                <Divider style={{ margin: '20px 0' }} />
                <div>
    <Typography variant="h6">Comentarios:</Typography>
    {comentarios.map(comentario => (
        <Paper key={comentario.id} elevation={3} style={{ padding: 15, margin: '10px 0' }}>
            <Typography variant="body1">Comentario: {comentario.message}</Typography>
            <Typography variant="body2">Usuario: {comentario.usuarioNombre}</Typography>
            <Typography variant="body2">
                <strong>Fecha de Creación:</strong> {format(new Date(comentario.createdDate), 'dd/MM/yyyy hh:mm a')}
            </Typography>
        </Paper>
    ))}
</div>
                <Typography variant="h6">Adjuntos:</Typography>
                <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
    {adjuntos.map((adjunto, index) => (
        <ImageListItem key={adjunto.id + index}>
            <img
                src={adjunto.url}
                alt={adjunto.filename}
                loading="lazy"
            />
            <ImageListItemBar
                title={adjunto.filename}
                subtitle={<span>tamaño: {formatearTamañoArchivo(adjunto.size)}</span>}
                actionIcon={
                    <>
                        <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label={`info about ${adjunto.filename}`}
                            onClick={() => abrirModalImagen(adjunto.url)}
                        >
                            <InfoIcon />
                        </IconButton>
                        <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label={`download ${adjunto.filename}`}
                            href={adjunto.url}
                            download
                        >
                            <GetAppIcon />
                        </IconButton>
                    </>
                }
            />
        </ImageListItem>
    ))}
    <Dialog open={modalAbierto} onClose={cerrarModalImagen}>
    <DialogTitle>Imagen Ampliada</DialogTitle>
    <DialogContent>
        <img src={urlImagenActual} alt="Imagen Ampliada" style={{ maxWidth: '100%', height: 'auto' }} />
    </DialogContent>
</Dialog>
</ImageList>
            </Container>
        </Layout>
    );
};

export default JobDetails;
